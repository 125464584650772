import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import WebRoundedIcon from '@mui/icons-material/WebRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import CircleNotificationsRoundedIcon from '@mui/icons-material/CircleNotificationsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
    Grid,
    Stack,
    useTheme
} from "@mui/material";
// import whiteLogo from "../assets/logos/DinoBooking_logo_oldalt_felirat-04.png"
import {Link} from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthContext";
import DinoDialog from "./Dialog";
import packageJson from '../../package.json'
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const {w} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)
    const [menuItems, setMenuItems] = useState([
        {name: 'Feed', url: '/', icon: <CameraAltRoundedIcon/>, employeeCanView: false},
        {name: 'E-mail címek', url: '/from-emails', icon: <AlternateEmailRoundedIcon/>, employeeCanView: true},
    ])
    const theme = useTheme()
    const {logoutUser, role} = useContext(AuthContext)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (role === 'employee') setMenuItems([...menuItems.filter(m => m.employeeCanView)])
    }, [role])

    const drawer = (
        <div style={{background: theme.palette.primary[500], height: '100%'}}>

            <Toolbar style={{background: theme.palette.primary[600]}}>
                {/*<img src={whiteLogo} alt="Dinobooking logo" width='100px'/>*/}
            </Toolbar>
            <Divider/>
            <List sx={{padding: '32px 16px'}}>
                {menuItems.map((item, index) => (
                    <ListItem key={item.name} className="menu-item" disablePadding sx={{paddingBottom: '3px'}}
                              component={Link}
                              to={item.url}>
                        <ListItemButton className="menu-button" sx={{background: props.label === item.name && theme.palette.primary[600], "&:hover": {background: theme.palette.primary[400]}}}>
                            <ListItemIcon className="menu-button__icon">
                                {item.icon}
                            </ListItemIcon>
                            <Typography variant="button" className="menu-button__text">{item.name}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Typography variant='caption' sx={{
                color: '#fff',
                position: 'absolute',
                bottom: 40,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }}>v. {packageJson.version}</Typography>
            <Button variant='text' sx={{
                color: '#fff',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {background: 'transparent'}
            }} startIcon={<LogoutRoundedIcon/>} onClick={() => setLogoutDialogOpen(true)}>kijelentkezés</Button>
        </div>
    );

    const container = w !== undefined ? () => w().document.body : undefined;

    const logoutActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setLogoutDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setLogoutDialogOpen(false)
            logoutUser()
        }} endIcon={<LogoutRoundedIcon/>}>Kijelentkezés</Button>
    </>

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar sx={{
                width: {md: `calc(100% - ${drawerWidth}px)`},
                ml: {md: `${drawerWidth}px`},
                background: {xs: theme.palette.primary[600], sm: theme.palette.primary[600], md: '#fff'}
            }} position="fixed">
                <Container maxWidth="xl">

                    <Toolbar disableGutters>
                        <Stack spacing={1} sx={{
                            display: {xs: 'none', sm: 'none', md: 'flex'},
                            paddingBottom: '5px',
                            paddingTop: '5px'
                        }}>
                            <Typography variant="headline2" noWrap component="div">
                                {props.label}
                            </Typography>
                            <Stack direction='row' spacing={2}>
                                {props.filterButtons?.map((button, i) =>
                                    <Button key={`f_button_${i}`} {...button.props}>{button.name}</Button>)}
                            </Stack>
                        </Stack>
                        <Box sx={{flexGrow: 0, display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
                            {/*<img src={whiteLogo} alt="Dinobooking logo" width='100px'/>*/}
                        </Box>
                        <Box sx={{display: {xs: 'flex', md: 'none'}, marginLeft: 'auto', zIndex: '11'}}>
                            <div style={{color: '#fff'}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleDrawerToggle}
                                    color="inherit"
                                >
                                    {!mobileOpen ? <MenuIcon/> : <CloseIcon/>}
                                </IconButton>
                            </div>
                        </Box>

                        <Stack sx={{
                            flexGrow: 1,
                            display: {xs: 'none', sm: 'none', md: 'flex'},
                            justifyContent: 'flex-end'
                        }} direction='row' spacing={1}>
                            {props.buttons?.map((button, i) =>
                                <Button
                                    key={`menu_button_${i}`}
                                    {...button.props}>{button.name}</Button>)}
                        </Stack>
                    </Toolbar>
                </Container>
                <Stack spacing={1} padding={1} direction='row' sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'space-between', background: '#fff'}}>
                    <Typography variant='headline2'>{props.label}</Typography>
                    <Stack spacing={1} direction='row'>
                        {props.filterButtonsMobile?.map((button, i) => <Button key={`f_button_${i}`} {...button.props}>{button.name}</Button>)}
                    </Stack>
                </Stack>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {md: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {sm: 'block', md: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'none', md: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, width: {sm: '100%', md: `calc(100% - ${drawerWidth}px)`}}}
            >
                {/*<Toolbar/>*/}

                {props?.filterButtonsMobile ? <Box sx={{height: 110, display: {xs: 'block', md: 'none'}}}/> : <Box sx={{height: 70, display: {xs: 'block', md: 'none'}}}/>}
                {props.children}

                <DinoDialog open={logoutDialogOpen}
                            handleClose={() => setLogoutDialogOpen(false)}
                            title='Kijelentkezés'
                            subtitle='Biztosan kijelentkezik?'
                            actions={logoutActions}>

                </DinoDialog>
            </Box>
        </Box>
    );
}

export default ResponsiveDrawer;