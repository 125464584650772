import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import greenTheme from "./components/themes/GreenTheme";
import {ConfigProvider} from "./context/ConfigContext";
import {AuthProvider} from "./context/AuthContext";
import Login from "./pages/authentication/Login";
import PrivateRoute from "./utils/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import FromEmails from "./pages/FromEmails/FromEmails";

function App() {
  return <ThemeProvider theme={greenTheme}>
    <ConfigProvider>
        <Router>
          <AuthProvider>
              <Switch>
                <PrivateRoute path="/" component={Dashboard} exact/>
                <PrivateRoute path="/from-emails" component={FromEmails} exact/>


                <Route path="/login" component={Login} exact/>

                {/*<Route component={NotFound}/>*/}
              </Switch>
          </AuthProvider>
        </Router>
    </ConfigProvider>
  </ThemeProvider>
}

export default App;
