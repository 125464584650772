import AuthBase from "../AuthBase";
import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";

import {useContext, useEffect, useState} from "react";

import Loading from "../../components/Loading";

import AuthContext from "../../context/AuthContext";
import useCallDataApi from "../../hooks/data";
import BatteryIndicator from "../../components/Battery";

const Dashboard = () => {
    const {user} = useContext(AuthContext)
    const {getData: fetchEntries} = useCallDataApi('entries')
    const {getData: fetchFromEmails} = useCallDataApi('from-emails')
    const [data, setData] = useState([])
    const [fromEmails, setFromEmails] = useState([])

    const [loading, setLoading] = useState(false)

    const FETCH_INTERVAL = 60000 * 5; // 1 min - 60000


    const fetchImages = () => {
        setLoading(true)
        console.log('FEETCH IMAGES')
        fetchEntries().then(e => setData(e)).finally(() => setLoading(false))
    }

    const fetchEmails = () => {
        setLoading(true)
        fetchFromEmails().then(f => setFromEmails(f)).finally(() => setLoading(false))
    }


    useEffect(() => {
        fetchImages() // Initial fetch
        fetchEmails()

        const intervalId = setInterval(fetchImages, FETCH_INTERVAL);

        return () => clearInterval(intervalId); // Cleanup on unmount
    }, []);

    const findFromEmail = id => fromEmails.find(f => f?.id === id)

    return <AuthBase label={`Üdv, ${user.name}!`}>
        <div className='main'>
            <Grid container spacing={2} marginTop={3}>
                {data.map(d => (
                    <Grid item key={d.id} xs={12} lg={4}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, border: '1px solid black', borderRadius: '10px', padding: 2}}>
                            <Typography variant='headline5'>{d?.taken_at} - {findFromEmail(d?.from_email)?.name}</Typography>
                            <Stack spacing={2}>
                                <Typography variant='body1'>Töltöttség: {Math.round(d?.Battery)}% <BatteryIndicator percentage={d?.Battery}/></Typography>
                                <Typography variant='body1'>Jelerősség: {d?.Signal}</Typography>
                                <Typography variant='body1'>Hőmérséklet: {Math.round(d?.Temperature)} C</Typography>
                                <Typography variant='body1'>SD kártya szabad terület (százalékban): {Math.round(d?.SD_card_free_space)}%</Typography>
                            </Stack>
                            <img src={d?.file}/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </div>
        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Dashboard