import React, {useImperativeHandle} from 'react';

const CustomForm = ({reference, children, errors, setErrors, data, setData, validators, ...rest}) => {

    const getData = () => {
        let formValid = true;
        const newErrors = {...errors}
        for (const fieldName in data) {
            const value = data[fieldName]
            for (let validator of validators[fieldName]) {
                const {valid, error} = validator(value)
                if (!valid) {
                    newErrors[fieldName] = error
                    formValid = false
                }
            }
        }
        setErrors(newErrors)

        if (formValid) {
            return data
        } else {
            return null
        }
    }

    useImperativeHandle(reference, () => ({
        getData
    }))

    return <form {...rest}>
        {children}
    </form>
}

export default CustomForm;