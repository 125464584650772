import React from 'react';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryStdIcon from '@mui/icons-material/BatteryStd';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';

const BatteryIndicator = ({ percentage }) => {
    let batteryIcon;

    if (percentage >= 75) {
        batteryIcon = <BatteryFullIcon style={{ color: 'green' }} />;
    } else if (percentage >= 50) {
        batteryIcon = <BatteryStdIcon style={{ color: 'green' }} />;
    } else if (percentage >= 25) {
        batteryIcon = <Battery20Icon style={{ color: '#ffb516' }} />;
    } else {
        batteryIcon = <BatteryAlertIcon style={{ color: 'red' }} />;
    }

    return batteryIcon
};

export default BatteryIndicator;