const cleanData = data => {
    let object = {};

    Object.keys(data).forEach(key => {
        const value = data[key]
        console.log(`getFormData -> forEach -> key: ${key} & value: ${value}`)

        if (key.startsWith('logicalfield-')) {
            const newKey = key.replace('logicalfield-', '')

            console.log(`getFormData -> forEach logicalfield  ->newKey: ${newKey} & value: ${value}`)

            object[newKey] = value === 'true'
        } else if (key.startsWith('nullable-')) {
            const newKey = key.replace('nullable-', '')

            console.log(`getFormData -> forEach nullable  ->newKey: ${newKey} & value: ${value}`)

            object[newKey] = value === '' ? null : value
        } else {
            if (value !== "") {

                console.log(`getFormData -> forEach else not empty value -> key: ${key} & value: ${value}`)

                object[key] = value
            } else {
                console.log('EMPTY!!!!')
            }
        }
        console.log('------------------------------------------------------')
    });
    return object
}

export {cleanData}