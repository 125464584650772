import AuthBase from "../AuthBase";
import {Box, Button, IconButton, Stack, Typography, useTheme} from "@mui/material";

import {useContext, useEffect, useRef, useState} from "react";

import Loading from "../../components/Loading";

import {useSnackbar} from "notistack";
import AuthContext from "../../context/AuthContext";
import useCallDataApi from "../../hooks/data";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DinoDialog from "../../components/Dialog";
import FromEmailData from "./FromEmailData";
import {cleanData} from "../../utils/FormUtils";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

const FromEmails = () => {
    const {user} = useContext(AuthContext)
    const {getData: fetchFromEmails, deleteData, createData, updateData} = useCallDataApi('from-emails')
    const [fromEmails, setFromEmails] = useState([])
    const [current, setCurrent] = useState(1)
    const [loading, setLoading] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [newDialogOpen, setNewDialogOpen] = useState(false)
    const fromEmailForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        setLoading(true)
        fetchFromEmails().then(r => setFromEmails(r)).finally(() => setLoading(false))
    }, [])

    const findFromEmail = id => fromEmails.find(f => f?.id === id)

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword)

    const deleteFromEmail = id => {
        setLoading(true)
        deleteData(id).then(r => {
            setFromEmails([...fromEmails.filter(c => c?.id !== id)])
            enqueueSnackbar('Törölve!', {variant: 'success'})
        }).finally(() => setLoading(false))
    }

    const editFromEmail = data => {
        setLoading(true)
        updateData(`${current}/`, data)
            .then(c => {
                const index = fromEmails.findIndex(fromEmail => fromEmail.id === current);
                if (index !== -1) {
                    const updatedFromEmails = [...fromEmails]
                    updatedFromEmails[index] = c
                    setFromEmails(updatedFromEmails)
                    enqueueSnackbar('Szerkesztve!', {variant: 'success'})
                }
            })
            .catch(r => enqueueSnackbar('Váratlan hiba történt!', {variant: 'error'}))
            .finally(() => setLoading(false))
    }

    const newFromEmail = (data) => {
        setLoading(true)
        createData(data)
            .then(c => {
                setFromEmails([...fromEmails, c])
                enqueueSnackbar('Hozzáadva!', {variant: 'success'})
            })
            .catch(r => enqueueSnackbar('Váratlan hiba történt!', {variant: 'error'}))
            .finally(() => setLoading(false))
    }

    const buttons = [{
        name: 'Új hozzáadása',
        props: {
            variant: 'contained',
            endIcon: <AddCircleOutlinedIcon/>,
            onClick: () => setNewDialogOpen(true),

        }
    }]


    return <AuthBase label={`Üdv, ${user.name}!`} buttons={buttons}>
        <div className='main'>

            <Stack spacing={2}>
                {fromEmails.map(d => <Stack key={d?.id} direction='row' justifyContent='space-between' sx={{border: '1px solid black', borderRadius: '10px', padding: 2}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                        <Typography variant='headline5'>{d?.name}</Typography>

                        <Stack spacing={2} justifyContent='center'>
                            <Typography variant='body1'>Mail szerver: {d?.mail_server}</Typography>
                            <Typography variant='body1'>Felhasználónév: {d?.username}</Typography>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Typography variant='body1'>
                                    Jelszó: {showPassword ? d.password : '•'.repeat(d.password?.length)}
                                </Typography>
                                <IconButton onClick={togglePasswordVisibility}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Box>
                    <Stack spacing={2}>
                        <Button variant='outlined' endIcon={<DeleteForeverRoundedIcon/>} onClick={() => {
                            setCurrent(d?.id)
                            setDeleteDialogOpen(true)
                        }}>Törlés</Button>
                        <Button variant='outlined' endIcon={<EditRoundedIcon/>} onClick={() => {
                            setCurrent(d?.id)
                            setEditDialogOpen(true)
                        }}>Szerkesztés</Button>
                    </Stack>
                </Stack>)}
            </Stack>

        </div>

        <DinoDialog
            title={`${findFromEmail(current)?.name} törlése`}
            open={deleteDialogOpen}
            handleClose={() => setDeleteDialogOpen(false)}
            subtitle='Ez a művelet nem vonható vissza'
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setDeleteDialogOpen(false)}>Mégsem</Button>
                <Button variant='outlined' onClick={() => {
                    setDeleteDialogOpen(false)
                    deleteFromEmail(current)
                }} color='error'>Törlés</Button>
            </Stack>}/>

        <DinoDialog
            title={`${findFromEmail(current)?.name} szerkesztése`}
            open={editDialogOpen}
            handleClose={() => setEditDialogOpen(false)}
            subtitle='Kuponkód szerkesztése'
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setEditDialogOpen(false)}>Mégsem</Button>
                <Button variant='outlined' onClick={() => {
                    const data = fromEmailForm.current.getData()
                    if (data) {
                        editFromEmail(cleanData(data))
                        setEditDialogOpen(false)
                    }
                }} color='success'>Szerkesztés</Button>
            </Stack>}>
            <FromEmailData reference={fromEmailForm} obj={findFromEmail(current)} />
        </DinoDialog>

        <DinoDialog
            title='Új e-mail cím'
            open={newDialogOpen}
            handleClose={() => setNewDialogOpen(false)}
            subtitle='E-mail hozzáadása'
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setNewDialogOpen(false)}>Mégsem</Button>
                <Button variant='outlined' onClick={() => {
                    const data = fromEmailForm.current.getData()
                    if (data) {
                        newFromEmail(cleanData(data))
                        setNewDialogOpen(false)
                    }
                }} color='success'>Hozzáadás</Button>
            </Stack>}>
            <FromEmailData reference={fromEmailForm}/>
        </DinoDialog>



        <Loading isLoading={loading}/>
    </AuthBase>
}

export default FromEmails