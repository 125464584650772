import {Stack} from "@mui/material";
import React, {useState} from "react";
import CustomTextField, {validateEmail, validateRequired} from "../../components/CustomTextField";
import CustomForm from "../../components/CustomForm";

const FromEmailData = ({reference, obj}) => {

    const [formData, setFormData] = useState({
        name: obj?.name || '',
        mail_server: obj?.mail_server || '',
        username: obj?.username || '',
        password: obj?.password || '',
    })

    const [errors, setErrors] = useState({
        name: false,
        mail_server: false,
        username: false,
        password: false,
    })

    const validators = {
        name: [validateRequired],
        mail_server: [validateRequired],
        username: [validateRequired, validateEmail],
        password: [validateRequired],
    };

    return <CustomForm reference={reference} errors={errors} setErrors={setErrors} data={formData} setData={setFormData} validators={validators} style={{marginTop: '15px'}}>
        <Stack spacing={2}>
            <CustomTextField
                label="Név"
                name="name"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <CustomTextField
                label="Mail szerver"
                name="mail_server"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <CustomTextField
                label="Felhasználónév"
                name="username"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <CustomTextField
                label="Jelszó"
                name="password"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

        </Stack>
    </CustomForm>
}

export default FromEmailData